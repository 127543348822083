import { marketplaceApi } from "..";

async function getMarketplace() {
  const res = await marketplaceApi.get("/marketplace");
  return res.data;
}

export default {
  getMarketplace,
};
