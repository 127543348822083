<script setup>
import { useRoute } from "vue-router";

import GoboIcon from "@/components/GoboIcon.vue";

const props = defineProps({
  app: {
    type: Object,
    required: true,
  },
  public: Boolean,
});
const route = useRoute();
</script>

<template>
  <RouterLink
    :to="{
      name: props.public ? 'PublicAppDetail' : 'AppDetail',
      params: Object.assign({}, route.params, { app: props.app.guid }),
    }"
    class="group rounded-lg border border-gray-300 bg-white p-6 h-64 overflow-clip shadow-sm hover:border-gray-400 focus:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gobo-primary"
  >
    <div class="flex flex-col">
      <GoboIcon class="w-16 h-16 inline-block mb-4 shrink-0" :value="app" />
      <div>
        <h2 class="text-md font-medium text-gray-900 mb-2">{{ app.name }}</h2>
        <p class="text-sm text-gray-500">
          {{ app.short_description }}
        </p>
      </div>
    </div>
  </RouterLink>
</template>
