<script setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import api from "@/clients/marketplace";
import { getTypeCategoryTitleFromQuery } from "@/lib/helpers";

import AppGrid from "../components/AppGrid.vue";
import AppListView from "../layouts/AppListView.vue";

const props = defineProps({ org: Object }); // eslint-disable-line no-unused-vars
const route = useRoute();

const apps = ref([]);
const error = ref(null);
const loading = ref(true);

const title = computed(() => {
  return getTypeCategoryTitleFromQuery(route.query);
});

async function fetchPublicApps() {
  apps.value = [];
  error.value = null;
  loading.value = true;

  return api
    .listPublicApps(route.query)
    .then((res) => {
      apps.value = res;
      loading.value = false;
    })
    .catch((err) => {
      error.value = err.message;
      loading.value = false;
    });
}

fetchPublicApps();
watch(route, () => fetchPublicApps());
</script>

<template>
  <AppListView>
    <div class="w-full">
      <h1 v-if="title" class="text-4xl font-semibold text-gray-900 mt-1 mb-8">
        {{ title }}
      </h1>
      <AppGrid :apps="apps" :loading="loading" :error="error" />
    </div>
  </AppListView>
</template>
