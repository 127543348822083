import { getParamsFromQuery } from "../../helpers";
import { marketplaceApi } from "..";

async function listPublicApps(query = {}) {
  const params = getParamsFromQuery(query, ["type", "category"]);

  let route = "/apps";
  if (params.size) {
    route += `?${params.toString()}`;
  }

  const res = await marketplaceApi.get(route);
  return res.data;
}

async function getPublicApp(appGuid) {
  const res = await marketplaceApi.get(`/apps/${appGuid}`);
  return res.data;
}

async function listApps(orgGuid, query = {}) {
  const params = getParamsFromQuery(query, ["type", "category"]);

  let route = `/orgs/${orgGuid}/apps`;
  if (params.size) {
    route += `?${params.toString()}`;
  }

  const res = await marketplaceApi.get(route);
  return res.data;
}

async function listInstalledApps(orgGuid) {
  const res = await marketplaceApi.get(
    `/orgs/${orgGuid}/apps?is_installed=true`,
  );
  return res.data;
}

async function getApp(orgGuid, appGuid) {
  const res = await marketplaceApi.get(`/orgs/${orgGuid}/apps/${appGuid}`);
  return res.data;
}

async function getAppSettings(orgGuid, appGuid) {
  const res = await marketplaceApi.get(
    `/orgs/${orgGuid}/apps/${appGuid}/settings`,
  );
  return res.data;
}

async function installApp(orgGuid, appGuid) {
  const res = await marketplaceApi.post(
    `/orgs/${orgGuid}/apps/${appGuid}/install`,
  );
  return res.data;
}

async function uninstallApp(orgGuid, appGuid) {
  const res = await marketplaceApi.delete(
    `/orgs/${orgGuid}/apps/${appGuid}/install`,
  );
  return res.data;
}

async function contactApp(appGuid, data) {
  const res = await marketplaceApi.post(`/apps/${appGuid}/contact`, data);
  return res.data;
}

export default {
  listPublicApps,
  getPublicApp,
  listApps,
  listInstalledApps,
  getApp,
  getAppSettings,
  installApp,
  uninstallApp,
  contactApp,
};
