<script setup>
import { computed } from "vue";

import NavBarView from "@/layouts/NavBarView.vue";
import { useCurrentUserStore } from "@/stores/currentUser";

const currentUser = useCurrentUserStore();
const icon = computed(() =>
  currentUser.marketplace
    ? currentUser.marketplace.logo || currentUser.marketplace.icon
    : null,
);
const name = computed(() =>
  currentUser.marketplace ? currentUser.marketplace.name : null,
);
</script>

<template>
  <div class="marketplace grow flex flex-col w-full max-w-5xl mx-auto">
    <NavBarView marketplace :icon="icon" :name="name" />
  </div>
</template>

<style>
html {
  @apply overflow-y-scroll;
}

#marketplace {
  @apply grow;
  @apply flex;
  @apply flex-col;
}

.marketplace {
  padding-left: 5%;
  padding-right: 5%;

  @screen xs {
    @apply px-6;
  }
}
</style>
