<script setup>
import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/24/solid";

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import AppCard from "../components/AppCard.vue";

const props = defineProps({
  apps: Array,
  public: Boolean,
  loading: Boolean,
  error: String,
});
</script>

<template>
  <LoadingSpinner v-if="props.loading" class="p-6 w-full" />
  <div
    v-else-if="props.error"
    class="flex items-center justify-center bg-white text-gray-700 rounded-lg px-6 py-6 shadow w-full"
  >
    <ExclamationTriangleIcon class="h-5 w-5 mr-1 mt-1 fill-gray-600" />
    {{ props.error }}
  </div>
  <div
    v-else-if="props.apps.length === 0"
    class="flex items-center justify-center bg-white text-gray-700 rounded-lg px-6 py-6 shadow w-full"
  >
    <QuestionMarkCircleIcon class="h-5 w-5 mr-1 mt-1 fill-gray-600" />
    No apps found.
  </div>
  <div
    v-else
    class="w-full grid grid-cols-1 auto-rows-fr gap-4 mb-6 xs:grid-cols-2 lg:grid-cols-3"
  >
    <AppCard
      v-for="app in props.apps"
      :key="app.guid"
      :app="app"
      :public="props.public"
    />
  </div>
</template>
