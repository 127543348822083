/**
 * Entrypoint for Vue Marketplace.
 */

import "@/lib/common";

import { createApp } from "vue";
import { plugin as formKitPlugin } from "@formkit/vue";
import { createPinia } from "pinia";

import { getFormKitConfig } from "@/lib/formkit.config";
import { initSentryVue } from "@/lib/sentry";
import App from "@/pages/marketplace/App.vue";
import router from "@/pages/marketplace/router";

const app = createApp(App);
const formKitConfig = getFormKitConfig();

initSentryVue(app, router);

app
  .use(router)
  .use(createPinia())
  .use(formKitPlugin, formKitConfig)
  .mount("#marketplace");
