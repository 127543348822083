import { paginatedUrl } from "../../helpers";
import { marketplaceApi } from "..";

async function listOrganizationUsers(orgGuid, page = 1) {
  const res = await marketplaceApi.get(
    paginatedUrl(`/orgs/${orgGuid}/users`, page),
  );
  return res.data;
}

async function getUser(userGuid) {
  const res = await marketplaceApi.get(`/users/${userGuid}`);
  return res.data;
}

export default {
  listOrganizationUsers,
  getUser,
};
