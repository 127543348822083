import { createRouter, createWebHistory } from "vue-router";

import {
  getOrgOr404,
  redirectAppDetailIfLoggedIn,
  redirectAppListIfLoggedIn,
  redirectToPublicAppsIfNotLoggedIn,
  redirectToRecentOrg,
  redirectUnderscoreOrg,
} from "@/lib/guards";
import { useCurrentUserStore } from "@/stores/currentUser";

import AppList from "./views/AppList.vue";
import OrgBase from "./views/OrgBase.vue";
import PublicAppList from "./views/PublicAppList.vue";

const mountpoint = window.__GOBO__.mountpoint;

const routes = [
  {
    name: "Index",
    path: "/",
    beforeEnter: [redirectToPublicAppsIfNotLoggedIn, redirectToRecentOrg],
  },
  {
    name: "PublicAppList",
    path: "/apps",
    component: PublicAppList,
    beforeEnter: [redirectAppListIfLoggedIn],
  },
  {
    name: "PublicAppDetail",
    path: "/apps/:app",
    component: () => import("./views/PublicAppDetail.vue"),
    beforeEnter: [redirectAppDetailIfLoggedIn],
  },
  {
    name: "Org",
    path: "/:org",
    component: OrgBase,
    redirect: () => {
      return { name: "AppList" };
    },
    beforeEnter: [redirectUnderscoreOrg, getOrgOr404],
    children: [
      {
        name: "AppList",
        path: "apps",
        component: AppList,
      },
      {
        name: "AppListInstalled",
        path: "installed",
        component: () => import("./views/AppListInstalled.vue"),
      },
      {
        name: "AppDetail",
        path: "apps/:app",
        component: () => import("./views/AppDetail.vue"),
      },
      {
        name: "AppSettings",
        path: "apps/:app/settings",
        component: () => import("./views/AppSettings.vue"),
      },
    ],
  },
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("@/components/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(`/${mountpoint}/`),
  routes,
});

router.beforeEach(() => {
  const currentUser = useCurrentUserStore();
  if (!currentUser.user) {
    currentUser.bootstrap();
  }
});

export default router;
