<script setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  getNotFoundLocation,
  getOrgFromRoute,
  setRecentOrg,
} from "@/lib/helpers";

const router = useRouter();
const route = useRoute();

const org = ref(null);

function setOrgOr404() {
  const newOrg = getOrgFromRoute(route);
  if (!newOrg) {
    return router.push(getNotFoundLocation(route));
  }
  org.value = newOrg;
  setRecentOrg(newOrg);
}

setOrgOr404();

watch(
  () => route.params.org,
  () => {
    if (route.params.org) {
      setOrgOr404();
    }
  },
);
</script>

<template>
  <main class="py-4 md:py-8">
    <router-view :org="org" />
  </main>
</template>
