<script setup>
import { computed } from "vue";

import { useCurrentUserStore } from "@/stores/currentUser";

const props = defineProps({ public: Boolean });
const currentUser = useCurrentUserStore();

const routeName = computed(() => {
  return props.public ? "PublicAppList" : "AppList";
});

const types = currentUser.appTypes;
const categories = currentUser.appCategories;
</script>

<template>
  <header class="mb-4">
    <nav>
      <h1 class="font-bold overflow-hidden text-lg mb-2 md:mt-1 md:mb-6">
        Marketplace
      </h1>
      <ul class="mb-4 md:mb-6 md:-mt-4">
        <li class="inline-block -ml-2 mr-4 md:block">
          <RouterLink
            class="link link--natural inline-block px-2 py-1 md:block"
            :to="{ name: routeName }"
          >
            View All
          </RouterLink>
        </li>
        <li v-if="!props.public" class="inline-block -ml-2 mr-4 md:block">
          <RouterLink
            class="link link--natural inline-block px-2 py-1 md:block"
            :to="{ name: 'AppListInstalled' }"
          >
            Installed Apps
          </RouterLink>
        </li>
      </ul>

      <div class="hidden md:block">
        <template v-if="types.length">
          <h2 class="font-bold overflow-hidden text-lg mb-2 md:mt-1 md:mb-6">
            Type
          </h2>
          <ul class="mb-4 md:mb-6 md:-mt-4">
            <li
              v-for="type in types"
              :key="type.slug"
              class="inline-block -ml-2 mr-4 md:block"
            >
              <RouterLink
                class="link link--natural inline-block px-2 py-1 md:block"
                :to="{ name: routeName, query: { type: type.slug } }"
              >
                {{ type.name }}
              </RouterLink>
            </li>
          </ul>
        </template>

        <template v-if="categories.length">
          <h2 class="font-bold overflow-hidden text-lg mb-2 md:mt-1 md:mb-6">
            Category
          </h2>
          <ul class="mb-4 md:mb-6 md:-mt-4">
            <li
              v-for="category in categories"
              :key="category.slug"
              class="inline-block -ml-2 mr-4 md:block"
            >
              <RouterLink
                class="link link--natural inline-block px-2 py-1 md:block"
                :to="{ name: routeName, query: { category: category.slug } }"
              >
                {{ category.name }}
              </RouterLink>
            </li>
          </ul>
        </template>
      </div>
    </nav>
  </header>
</template>
