<script setup>
import { useAttrs } from "vue";

import AppSidebar from "../components/AppSidebar.vue";

const props = defineProps({ public: Boolean });
const attrs = useAttrs();
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <AppSidebar
      :public="props.public"
      class="md:w-48 md:shrink-0 md:grow-0 lg:w-56"
    />

    <slot v-bind="attrs" />
  </div>
</template>
