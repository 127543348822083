import client from "../client";

import apps from "./endpoints/apps";
import marketplace from "./endpoints/marketplace";
import organizations from "./endpoints/organizations";
import users from "./endpoints/users";

export const marketplaceApi = client;

export default {
  ...marketplace,
  ...users,
  ...organizations,
  ...apps,
};
