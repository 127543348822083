import { paginatedUrl } from "../../helpers";
import { marketplaceApi } from "..";

async function listUserOrganizations(userGuid, page = 1) {
  const res = await marketplaceApi.get(
    paginatedUrl(`/users/${userGuid}/orgs`, page),
  );
  return res.data.results;
}

async function getOrganization(orgGuid) {
  const res = await marketplaceApi.get(`/orgs/${orgGuid}`);
  return res.data;
}

export default {
  listUserOrganizations,
  getOrganization,
};
